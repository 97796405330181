import { createAction, props } from '@ngrx/store'

import { ProjectDateRangeRequest } from '~common/components/filters/date-range/date-range.model'
import { CategoryMatrix, JsonResponse } from '~core/models'
import { createHTTPActions } from '~store/utils.actions'

import {
  FacebookPage,
  Insight,
  InsightsOverviewLegacy,
  InsightsRequest,
  Post,
  PostRequest,
  Reel,
  ReelRequest,
  Video,
  VideoRequest,
} from '../models'

export const setFacebookPage = createAction('[Facebook Page] Set FacebookPage', props<{ payload: FacebookPage }>())

export const unsetActivePost = createAction('[Facebook Page] Unset Active Post')

export const unsetActiveVideo = createAction('[Facebook Page] Unset Active Video')

export const unsetActiveReel = createAction('[Facebook Page] Unset Active Reel')

export const [loadInsights, loadInsightsSuccess, loadInsightsError] = createHTTPActions<
  InsightsRequest,
  JsonResponse<Insight[]>
>('[Facebook Page] Load Insights')

export const [loadInsightsOverview, loadInsightsOverviewSuccess, loadInsightsOverviewError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<InsightsOverviewLegacy>
>('[Facebook Page] Load Insights Overview')

export const [loadPost, loadPostSuccess, loadPostError] = createHTTPActions<PostRequest, JsonResponse<Post>>(
  '[Facebook Page] Load Post',
)

export const [loadVideo, loadVideoSuccess, loadVideoError] = createHTTPActions<VideoRequest, JsonResponse<Video>>(
  '[Facebook Page] Load Video',
)
export const [loadReel, loadReelSuccess, loadReelError] = createHTTPActions<ReelRequest, JsonResponse<Reel>>(
  '[Facebook Page] Load Reel',
)

export const [loadOnlineFansPerHour, loadOnlineFansPerHourSuccess, loadOnlineFansPerHourError] = createHTTPActions<
  ProjectDateRangeRequest,
  JsonResponse<CategoryMatrix>
>('[Facebook Page] Load Online Fans Per Hour')

export const resetState = createAction('[Facebook Page] Reset state')
