import { HttpErrorResponse } from '@angular/common/http'
import { createReducer, on } from '@ngrx/store'
import * as R from 'ramda'

import { CategoryMatrix } from '~core/models'
import { setError, setIsLoading } from '~store/utils.store'

import { Insight, InsightsOverviewLegacy, Post, Reel, Video } from '../models'
import * as FacebookPageActions from './facebook-page.actions'

export const facebookPageFeatureKey = 'facebookPage'

export interface State {
  activePost: Post & { clicks: number }
  activeVideo: Video
  activeReel: Reel
  error: HttpErrorResponse
  insights: Insight[]
  insightsIsLoading: boolean
  insightsOverview: InsightsOverviewLegacy
  onlineFansIsLoading: boolean
  onlineFansPerHour: CategoryMatrix
  posts: Post[]
  postsIsLoading: boolean
  previousInsights: Insight[]
  videos: Video[]
  videosIsLoading: boolean
  reels: Reel[]
  reelsIsLoading: boolean
}

export const initialState: State = {
  activePost: null,
  activeVideo: null,
  activeReel: null,
  error: null,
  insights: [],
  insightsOverview: null,
  insightsIsLoading: false,
  onlineFansIsLoading: false,
  onlineFansPerHour: null,
  posts: [],
  postsIsLoading: false,
  previousInsights: [],
  videos: [],
  videosIsLoading: false,
  reels: [],
  reelsIsLoading: false,
}

const setInsights = (state, action): State => ({
  ...state,
  insightsIsLoading: false,
  insights: action.payload.data.slice(action.payload.data.length / 2),
  previousInsights: action.payload.data.slice(0, action.payload.data.length / 2),
  error: null,
})

const setInsightsOverview = (state, action): State => ({
  ...state,
  insightsOverview: action.payload.data,
  error: null,
})

const setOnlineFansPerHour = (state, action): State => ({
  ...state,
  onlineFansIsLoading: false,
  onlineFansPerHour: action.payload.data,
  error: null,
})

const setPosts = (state, action): State => ({
  ...state,
  postsIsLoading: false,
  posts: action.payload.data,
  error: null,
})

const setActivePost = (state, action): State => ({
  ...state,
  activePost: {
    ...action.payload.data,
    clicks: R.sum(R.values(action.payload.data.clicksByType)),
  },
  postsIsLoading: false,
  error: null,
})

const unsetActivePost = (state): State => ({
  ...state,
  activePost: null,
})

const setVideos = (state, action): State => ({
  ...state,
  videosIsLoading: false,
  videos: action.payload.data,
  error: null,
})

const setActiveVideo = (state, action): State => ({
  ...state,
  activeVideo: action.payload.data,
  videosIsLoading: false,
  error: null,
})

const unsetActiveVideo = (state): State => ({
  ...state,
  activeVideo: null,
})

const setReels = (state, action): State => ({
  ...state,
  reelsIsLoading: false,
  reels: action.payload.data,
  error: null,
})

const setActiveReel = (state, action): State => ({
  ...state,
  activeReel: action.payload.data,
  reelsIsLoading: false,
  error: null,
})

const resetState = (): State => initialState

const unsetActiveReel = (state): State => ({
  ...state,
  activeReel: null,
})

export const reducer = createReducer(
  initialState,

  on(FacebookPageActions.loadInsights, setIsLoading('insights')),

  on(FacebookPageActions.loadOnlineFansPerHour, setIsLoading('onlineFans')),

  on(
    FacebookPageActions.loadInsightsError,
    FacebookPageActions.loadInsightsOverviewError,
    FacebookPageActions.loadPostError,
    FacebookPageActions.loadVideoError,
    FacebookPageActions.loadReelError,
    FacebookPageActions.loadOnlineFansPerHourError,
    setError,
  ),

  on(FacebookPageActions.loadInsightsSuccess, setInsights),

  on(FacebookPageActions.loadInsightsOverviewSuccess, setInsightsOverview),

  on(FacebookPageActions.loadOnlineFansPerHourSuccess, setOnlineFansPerHour),

  on(FacebookPageActions.loadPostSuccess, setActivePost),

  on(FacebookPageActions.unsetActivePost, unsetActivePost),

  on(FacebookPageActions.loadVideoSuccess, setActiveVideo),

  on(FacebookPageActions.unsetActiveVideo, unsetActiveVideo),

  on(FacebookPageActions.loadReelSuccess, setActiveReel),

  on(FacebookPageActions.unsetActiveReel, unsetActiveReel),

  on(FacebookPageActions.resetState, resetState),
)
