import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'

import { withPreviousPeriod } from '~core/utils/api'
import { FacebookPageApiService } from '~features/facebook-page/services/facebook-page-api.service'

import * as FacebookPageActions from './facebook-page.actions'

@Injectable()
export class FacebookPageEffects {
  loadInsights$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacebookPageActions.loadInsights),
      exhaustMap(({ payload }) => {
        return this.facebookPageApiService.insights(withPreviousPeriod(payload)).pipe(
          map((insights) => FacebookPageActions.loadInsightsSuccess(insights)),
          catchError((err: HttpErrorResponse) => of(FacebookPageActions.loadInsightsError(err))),
        )
      }),
    )
  })

  loadOnlineFansPerHour$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacebookPageActions.loadOnlineFansPerHour),
      exhaustMap(({ payload }) => {
        return this.facebookPageApiService.onlineFansPerHour(payload).pipe(
          map((onlineFansPerHour) => FacebookPageActions.loadOnlineFansPerHourSuccess(onlineFansPerHour)),
          catchError((err: HttpErrorResponse) => of(FacebookPageActions.loadOnlineFansPerHourError(err))),
        )
      }),
    )
  })

  loadPost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacebookPageActions.loadPost),
      exhaustMap(({ payload }) => {
        return this.facebookPageApiService.post(payload).pipe(
          map((post) => FacebookPageActions.loadPostSuccess(post)),
          catchError((err: HttpErrorResponse) => of(FacebookPageActions.loadPostError(err))),
        )
      }),
    )
  })

  loadReel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacebookPageActions.loadReel),
      exhaustMap(({ payload }) => {
        return this.facebookPageApiService.reel(payload).pipe(
          map((reel) => FacebookPageActions.loadReelSuccess(reel)),
          catchError((err: HttpErrorResponse) => of(FacebookPageActions.loadReelError(err))),
        )
      }),
    )
  })

  loadVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacebookPageActions.loadVideo),
      exhaustMap(({ payload }) => {
        return this.facebookPageApiService.video(payload).pipe(
          map((video) => FacebookPageActions.loadVideoSuccess(video)),
          catchError((err: HttpErrorResponse) => of(FacebookPageActions.loadVideoError(err))),
        )
      }),
    )
  })

  constructor(
    private actions$: Actions,
    private facebookPageApiService: FacebookPageApiService,
  ) {}
}
