import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@jsverse/transloco'
import { provideEffects } from '@ngrx/effects'
import { provideState } from '@ngrx/store'

import { translocoScopeLoader } from '~core/utils'

import { FacebookPageEffects } from './store/facebook-page.effects'
import * as fromFacebookPage from './store/facebook-page.reducer'

export const fbPageConfig: ApplicationConfig['providers'] = [
  provideState(fromFacebookPage.facebookPageFeatureKey, fromFacebookPage.reducer),
  provideEffects(FacebookPageEffects),
]

export const fbPageI18nConfig: ApplicationConfig['providers'] = [
  // Provided via translationsConfig in app.config
  provideTranslocoScope({
    scope: 'facebookPage',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
